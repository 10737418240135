import React from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Translate from "translate-components";
import * as actions from "../../data/Certificates/certificatesActions";
/* ********* Assets ********* */
const CourseItemComponent = (props) => {
  const dispatch = useDispatch();
  /* ********* Reducer Variables ********* */
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const isLoading = useSelector(
    (state: RootStateOrAny) => state.certificate.certificateLoading
  );
  /* ********* Constants ********* */
  const isCompleted = props.item.coursesCount === props.item.completedVideos;
  const styles = {
    completionBar: {
      width: props.item.completionPercentage + "%",
    },
  };

  return (
    <>
      <div className="col-12 col-lg-6 ">
        <div className="dashCourse">
          <div className="dashCourseThumb">
            <a href={props.goToCourse()}>
              <img src={props.item.img} className="splash" alt="" />
            </a>
          </div>
          <div className="dashCourseDetailsContainer">
            <div className="dashCourseDetails">
              <div className="courseText">
                <h6 className="bottom-0 top-5 text-truncate">
                  {props.item.name}
                </h6>
                <p>{props.item.description}</p>
              </div>
            </div>

            <div className="courseFooter">
              <div className="d-flex flex-column flex-column-reverse flex-sm-row justify-content-between">
                <a
                  href={props.goToCourse()}
                  target="_top"
                  className="startCourse"
                >
                  <Translate>main.coursepreview.startcourse</Translate>
                </a>
                {isCompleted ? (
                  isLoading ? (
                    <Translate>Certificate_Downloading</Translate>
                  ) :  props.item.hasCertificate == true ?
                    <a
                      onClick={() =>
                        dispatch(
                          actions.downloadCertificate(
                            userSession,
                            props.item.id,
                            lang
                          )
                        )
                      }
                      className="startCourse"
                    >
                      <Translate>downloadcerticate</Translate>
                    </a>
                  :  <>
                      <div className="course-completion"> </div>
                      <div className="course-completion">
                        <div className="d-flex align-items-center">
                          <i className="material-icons text-success">
                            check_circle_outline
                          </i>
                          <span className="text-uppercase text-success fs-6">
                            <Translate>completed</Translate>
                          </span>
                        </div>
                      </div>
                    </>
                ) :   
              null
              }
              </div>
              <div className="percentComplet">
                <Translate>lessonscompleted</Translate>&nbsp;
                {props.item.completedVideos + " of " + props.item.coursesCount}
              </div>
              <div className="progressBar">
                <div className="progressMade" style={styles.completionBar} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CourseItemComponent.propTypes = {
  userName: PropTypes.string,
  item: PropTypes.object.isRequired,
  goToCourse: PropTypes.func,
};
export default CourseItemComponent;
