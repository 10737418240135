import React from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import Translate from "translate-components";
import { useNavigate } from "react-router-dom";
import { encodeNumber } from "../../utils/strings";
import GetPriceByModuleOrGroup from "../../data/UpfrontPrices/UpfrontPrices";

const CourseItemComponent = (params) => {
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const navigate = useNavigate();
  var marketingInfo = params.instituteModuleMarketing?.filter(
    (im) => im.lang == lang
  );
  const buyUrl =
    params == null
      ? ""
      : params.buyInformation != null && params.buyInformation.length > 0
      ? params.buyInformation[0].buyUrl
      : "";
  const showDetails = () => {
    process.env.REACT_APP_SHOW_ABOUT_IN_COURSES == "true" &&
      params.isUpcoming != true &&
      navigate(`/landing/course/${params.id}`, { replace: true });
    //Logic for prevent Details if the Buy is not allowed
    process.env.REACT_APP_SHOW_ABOUT_IN_COURSES !== "true" &&
      params.isUpcoming != true &&
      navigate(goToCourse("/course/" + encodeNumber(params.id)));
  };

  const style = "col-12 col-sm-6 col-md-4 col-lg-3 course ";
  const wrapperStyle =
    params.style != null ? style + params.style.replace(".", "") : style;

  const goToCourse = (purl) => {
    if (userSession && userSession.isLoggedIn()) return purl;
    else return "/login";
  };

  return (
    <div className={wrapperStyle}>
      <div className="card bottom-20 preview-card">
        <div
          className="dashPrevThumb"
          onClick={
            params.previewVideoUrl != ""
              ? () => params.showModal(params)
              : () => {}
          }
        >
          <a className={params.previewVideoUrl == "" ? "nopreview" : ""}>
            <img src={params.img} className="splash" />
          </a>
        </div>
        <div className="padding-all-10 LNcourseTitle" onClick={showDetails}>
          <h5>{params.name}</h5>
        </div>

        <div className="padding-all-10 LNcourseDesc" onClick={showDetails}>
          {params.description}
        </div>
        {params.allowBuy && (
          <div className="text-success text-center fs-5 bkg-papergray">
            {GetPriceByModuleOrGroup(params.id, true)}{" "}
            {GetPriceByModuleOrGroup(params.id, true) && <sup>USD</sup>}
          </div>
        )}

        {params.isEnrolled && userSession && userSession.isLoggedIn() ? (
          <>
            <div className="height-40"></div>
            <a href={goToCourse("/course/" + encodeNumber(params.id))}>
              <span className="btn btn-flat-accent2 text-success width-100p top-5 text-capitalize upcoming-product">
                {params.allowBuy && <Translate>GoToCourse</Translate>}
                {params.allowBuy == false && <Translate>Start</Translate>}
              </span>
            </a>
          </>
        ) : (
          <>
            {params.isUpcoming !== true ? (
              <>
                {process.env.REACT_APP_SHOW_ABOUT_IN_COURSES == "true" && (
                  <a onClick={showDetails}>
                    <span className="btn btn-flat-background text-secondary width-100p top-5 text-capitalize border-t-papergray">
                      <Translate>main.coursepreview.aboutcourse</Translate>
                    </span>
                  </a>
                )}

                {params.allowBuy && params.buyAllgroup != true && (
                  <a href={buyUrl}>
                    <span className="btn btn-flat-papergray text-success width-100p text-capitalize">
                      <Translate>Dashboard_Buy_Course</Translate>
                    </span>
                  </a>
                )}
              </>
            ) : (
              <span className="btn btn-flat-papergray text-success width-100p text-capitalize upcoming-product">
                <Translate>upcoming</Translate>
              </span>
            )}
            <>
              {params.allowBuy == false && params.isUpcoming != true && (
                <a
                  href={goToCourse("/course/" + encodeNumber(params.id))}
                  className="upcoming-product text-success w-100 btn btn-flat-accent2"
                >
                  <Translate>Start</Translate>
                </a>
              )}
            </>
          </>
        )}

        <div className="tooltip-content">
          {marketingInfo != null && marketingInfo.length > 0 ? (
            <>
              <h1 className="tooltip_title">
                {marketingInfo != null && marketingInfo.length > 0
                  ? marketingInfo[0].title
                  : ""}
              </h1>
              <p className="tooltip_description">
                {marketingInfo != null && marketingInfo.length > 0
                  ? marketingInfo[0].text
                  : ""}
              </p>

              <div className="tooltip-detail">
                <span className="material-icons">smart_display</span>
                <p>
                  {marketingInfo != null && marketingInfo.length > 0
                    ? marketingInfo[0].videoLength
                    : ""}
                </p>
              </div>

              <div className="tooltip-detail">
                <span className="material-icons">download</span>
                <p>
                  {marketingInfo != null && marketingInfo.length > 0
                    ? marketingInfo[0].mediaResourcesCount
                    : ""}
                </p>
              </div>
            </>
          ) : (
            <>
              <h1 className="tooltip_title">{params.name}</h1>
              <p className="tooltip_description">{params.description}</p>
            </>
          )}

          <div className="tooltip-detail">
            <span className="material-icons">flag_circle</span>
            <p>
              <Translate>CertificateOfCompletion</Translate>
            </p>
          </div>

          <div>
            {params.isUpcoming == true ? (
              <span>
                <Translate>upcoming</Translate>
              </span>
            ) : params.isEnrolled && params.allowBuy ? (
              <a
                href={goToCourse("/course/" + params.id)}
                className="btn btn-flat-success w-100"
              >
                <Translate>GoToCourse</Translate>
              </a>
            ) : (
              params.allowBuy &&
              params.buyAllgroup !== true && (
                <a href={buyUrl} className="btn btn-flat-success w-100">
                  <Translate>Dashboard_Buy_Course</Translate>
                </a>
              )
            )}
            {params.allowBuy == false && params.isUpcoming != true && (
              <a
                href={goToCourse("/course/" + encodeNumber(params.id))}
                className="btn btn-flat-success w-100"
              >
                <Translate>Start</Translate>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseItemComponent;
