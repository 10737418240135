import React from "react";
import PropTypes from "prop-types";
import Translate from "translate-components";
import { configureStore } from "@reduxjs/toolkit";
import { encodeNumber } from "../../utils/strings";
type CourseVideoItemProps = {
  idComponent:string | undefined,
  refComponent:any,
  course: any,
  goToReference: string,
  coursesList: [any],
};

const CourseVideoItemComponent = ({idComponent, refComponent,course,goToReference,coursesList}:CourseVideoItemProps) => {
  
  /* ********* Constants ********* */
  const isLockedStyle =
  course.isLocked || course.readyToDisplay == false;
  
  /* ********* Functions ********* */
  const prerequisiteText = (c) => {
    if (typeof c == "undefined" || c === null) return "";
    var type = "";
    var icon = "";
    switch (c.fkInstituteCourseType) {
      case 1:
        type = "course_type_video";
        icon = "ondemand_video";
        break;
      case 2:
        type = "course_type_reading";
        icon = "menu_book";
        break;
      case 3:
        type = "course_type_quiz";
        icon = "fact_check";
        break;
      case 4:
        type = "course_type_discution";
        icon = "supervised_user_circle";
        break;
      case 5:
        type = "course_type_donwload_material";
        icon = "download";
        break;  
      case 6:
        type = "course_type_donwload_material";
        icon = "info";
        break;        
    }
    return (
      <>
        <i className="material-icons">{icon}</i> <Translate>{type}</Translate>
        {"  "}
        <span>{c?.name}</span>
      </>
    );
  };

  const  appendRequisites = (previousValue, currentValue, index) =>[
    ...previousValue,
    <li key={index}>
      {prerequisiteText(
        coursesList.find((v) => v.id == currentValue)
      )}
    </li>,
  ]

  const prerequisites = String(course.prerequisites)
    .split(",")
    .reduce(appendRequisites,
      []
    );

  const readyToDisplay = (v, defaultDisplay) => {
    if (
      course.readyToDisplay == null ||
      course.readyToDisplay == true
    )
      return defaultDisplay;
    else
      return (
        <div className="upcoming-icon">
          <i className="material-icons">
            <img height="60px" src={"../../assets/images/ComingSoon.png"}></img>
          </i>
          <Translate>upcoming</Translate>
        </div>
      );
  };


  return (
    <div
      id={idComponent}
      ref={refComponent}
      className={
        isLockedStyle
          ? "list-item locked" +
            " indentation-" +
            course.indentation +
            " " +
            course.style
          : "list-item " +
            " indentation-" +
            course.indentation +
            " " +
            course.style
      }
    >
      <div className="video-mark" />
      
      <div className="video-icons">
        <div className="video-image">
          <img src={course.img} className="splash" alt="" />
        </div>
      </div>

      <div className="item-description ">
        <h1>
          {course.name}
        </h1>
        <div className="course-title">
        </div>
        <div className="course-description">{course.description}</div>
      </div>

      {course.isLocked ? (
        <div className="lock-info">
          <div className="lock-title">
            <span className="material-icons">info</span>
            <span>
              <Translate>require_unlock</Translate>
            </span>
          </div>
          <div className="requirements">
            {/* <Translate>complete_previous</Translate> */}
            <ul>{prerequisites}</ul>
          </div>
        </div>
      ) : (
        <>
          {course.isCompleted ? (
            <>
              <div className="course-completion"> </div>
              <div className="course-completion">
                <div className="completion">
                  <i className="material-icons text-success">
                    check_circle_outline
                  </i>
                  <span className="completion-title">
                    <Translate>completed</Translate>
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="course-completion notCompletedSpacer"> </div>
              <div className="course-completion">
                <div className="completion notCompleted">
                  <i className="material-icons">
                    priority_high
                  </i>
                  <span className="completion-title">
                    Not Completed
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {readyToDisplay(
        course,
        course.isLocked ? (
          <div className="access-course">
            <i className="material-icons text-accent3">lock</i>
            <span>
              <Translate>Locked</Translate>
            </span>
          </div>
        ) : (
          <div className="access-course" >
            <span className="material-icons">info</span>
            <span>
            </span>
          </div>
        )
      )}
    </div>
  );
};


export default CourseVideoItemComponent;
